import React, { useContext, useEffect } from "react";
import { ClassCategoryContext } from "../../context/ClassCategoryContext";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { VideoTypeContext } from "../../context/VideoTypeContext";
import { FiltersContext } from "../../context/FiltersContext";
import { CoachesContext } from "../../context/CoachesContext";
import { VideosContext } from "../../context/VideosContext";
import FilterVideoProps from "../filters/FilterVideoProps";
import { TagsContext } from "../../context/TagsContext";
import FilterOption from "../videos/FilterOption";
import useFilters from "../../hooks/useFilters";
import { durations } from "../../utils";


const FilterClassType = () => {
  const { completed, setFilter, favorites, query } = useContext(FiltersContext);
  const { class_categories } = useContext(ClassCategoryContext);
  const { video_types } = useContext(VideoTypeContext);
  const { coaches } = useContext(CoachesContext);
  const { tags } = useContext(TagsContext);

  const { getAllAttributes, clearAllFilters } = useFilters();

  useEffect(() => {
    getAllAttributes();
  }, []);

  const renderMessage = () => {
    if (favorites || completed) {
      return (
        <p className="mb-0">
          Estás viendo: Tus clases {favorites ? "Favoritas" : "Completadas"}
        </p>
      );
    }
  };

  return (
    <div className="filter-card card no-scale shadow-sm">
      <div className="row align-items-center">
        <div className="col-12 col-md-4">
          <label className="mb-1">Buscar una Clase</label>
          <input
            type="text"
            className="form-control"
            value={query}
            placeholder="Buscar por nombre de clase..."
            onChange={(e) => setFilter("query", e.target.value)}
          />
        </div>
        {/*
          <div className="col-12 col-md-3">
            <FilterVideoProps />
          </div>
        */}
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-6 col-md-4">
              <FilterOption
                label="Categoría"
                id_key="class_category_id"
                options={class_categories}
              />
            </div>
            <div className="col-6 col-md-2">
              <FilterOption
                label="Coach"
                id_key="instructor_id"
                options={coaches}
              />
            </div>
            <div className="col-6 col-md-2">
              <FilterOption
                label="Duración"
                id_key="duration"
                options={durations}
              />
            </div>
            <div className="col-6 col-md-2">
              <FilterOption label="Target" id_key="tag_id" options={tags} />
            </div>
            <div className="col-6 col-md-2">
              <FilterOption
                label="Impacto"
                id_key="video_type_id"
                options={video_types}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center pt-3">
        <div className="col-12 col-lg-6">
          <button
            className="btn btn-light shadow-sm border me-2"
            onClick={clearAllFilters}
          >
            <i className="fa fa-undo-alt"></i>
            <span className="ms-2">Limpiar Filtros</span>
          </button>
          <button
            className={`btn btn-light shadow-sm border me-2 ${
              completed ? "text-success" : "text-dark"
            }`}
            onClick={() => setFilter("completed", !completed)}
          >
            <i className="fa fa-check"></i>
            <span className="hide-mobile ms-2">Completadas</span>
          </button>
          <button
            className={`btn btn-light shadow-sm border ${
              favorites ? "text-danger" : "text-dark"
            }`}
            onClick={() => setFilter("favorites", !favorites)}
          >
            <i className="fa fa-heart"></i>
            <span className="hide-mobile ms-2">Favoritos</span>
          </button>
        </div>
        <div className="col-12 col-lg-6">{renderMessage()}</div>
      </div>
    </div>
  );
};

export default FilterClassType;
