import React, { useContext, useEffect } from "react";
import { CancelReasonsContext } from "../../context/CancelReasonsContext";
import { Link } from "@reach/router";

const CancelForm = ({
  purchase,
  reason,
  setReason,
  detail,
  setDetail,
  validReason,
  handleSubmit,
}) => {
  const { cancelReasons, getCancelReasons } = useContext(CancelReasonsContext);

  useEffect(() => {
    getCancelReasons();
  }, []);

  useEffect(() => {
    if (Array.isArray(cancelReasons)) {
      const first = cancelReasons[0];
      if (first) setReason(first.name);
      else setReason("otro");
    }
  }, [cancelReasons]);

  const renderOtro = () => {
    if (reason === "otro") {
      return (
        <div>
          <label>Por favor, especifica el motivo de tu cancelación.</label>
          <input
            type="text"
            value={detail}
            className="form-control mb-3"
            onChange={(e) => setDetail(e.target.value)}
          />
        </div>
      );
    }
  };

  const renderOptions = () => {
    if (Array.isArray(cancelReasons)) {
      return cancelReasons.map((cancelReason) => (
        <option value={cancelReason.name} key={cancelReason.id}>
          {cancelReason.name}
        </option>
      ));
    }
  };

  const renderPurchase = () => {
    if (purchase && purchase !== null) {
      return (
        <p>
          ¿Estás segura que deseas cancelar tu suscripción #
          {purchase.purchase_id} a {purchase.class_package.title}?
        </p>
      );
    }
  };

  return (
    <div>
      <h1 className="text-center h3">¿Segura de tu Decisión?</h1>
      {renderPurchase()}
      <label>¿Cuál es el motivo de tu cancelación?</label>
      <select
        onChange={(e) => setReason(e.target.value)}
        className="form-control mb-3"
        value={reason}
      >
        {renderOptions()}
        <option value="otro">Otro</option>
      </select>
      {renderOtro()}
      <button
        disabled={!validReason}
        className="btn btn-outline-danger w-100 btn-small mb-3"
        onClick={handleSubmit}
      >
        Entiendo, Cancelar Mi Membresía
      </button>
      <Link to="/mytbm" className="btn btn-secondary w-100 bold text-uppercase">
        Cambie de Opinión
      </Link>
    </div>
  );
};

export default CancelForm;
