import { Link } from "@reach/router";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Navbar = () => {
  const { user } = useContext(AuthContext);
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow-sm vw-100">
      <div className="container-fluid px-0 navbar-container">
        <a className="navbar-brand p-2" href="/#landing">
          <img src="/img/logo.png" className="logo-navbar" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid px-1 px-md-3 pe-md-5">
            <ul className="navbar-nav nav justify-content-end">
              <li className="nav-item">
                <a className="nav-link" href="/#about">
                  Conócenos
                </a>
              </li>
              <li
                className={`nav-item ${
                  window.location.href.includes("paquetes")
                    ? "nav-item-active"
                    : ""
                }`}
              >
                <Link className="nav-link" to="/paquetes">
                  Paquetes
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://tbmcertifications.com/"
                  target="_blank"
                >
                  Certificaciones
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://shop.thebodymethod.mx/"
                  target="_blank"
                >
                  Shop
                </a>
              </li>
              <li className="ms-md-3">
                <Link
                  className="btn btn-primary bold navbar-cta text-uppercase"
                  to="/entrar"
                >
                  <i className="fa fa-user"></i>{" "}
                  {user === null ? <span className="ms-1">Ingresar</span> : ""}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
