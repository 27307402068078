import { Link, navigate } from "@reach/router";
import React, { useEffect, useContext } from "react";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";
import { handleWorkoutPlanner } from "../../utils/workout_planner";

const OnlineHeader = ({ title, backUrl, backEnabled }) => {
  const { customer_workout_plan, getActiveCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );

  useEffect(() => {
    getActiveCustomerWorkoutPlan();
  }, []);

  const renderButton = () => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      return (
        <button
          onClick={() => handleWorkoutPlanner(customer_workout_plan)}
          className="btn btn-primary"
        >
          Mi Workout Planner
        </button>
      );
    }
    return (
      <Link to="/pages/workout-planner" className="btn btn-primary">
        <span className="hide-mobile">AYÚDAME A </span>ORGANIZAR MIS CLASES
      </Link>
    );
  };
  return (
    <div className="row panel-title align-items-center">
      <div className="col-6 px-0">
        <div className="d-flex align-items-center">
          {backEnabled && (
            <Link
              to={backUrl && backUrl !== null ? backUrl : "/mytbm/online"}
              className="btn btn-light br-25 d-inline-block me-2"
            >
              <i className="fa fa-chevron-left"></i>{" "}
            </Link>
          )}{" "}
          <h1 className="mb-0 d-inline-block futura text-sentence text-uppercase pt-2">
            {title ? title : "TBM Online"}
          </h1>
        </div>
      </div>
      <div className="col-6 px-0 text-right">{renderButton()}</div>
    </div>
  );
};

export default OnlineHeader;
