import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../context/ModalContext";
import ConfirmCancel from "../components/purchases/ConfirmCancel";
import { PurchasesContext } from "../context/PurchasesContext";
import { CheckoutContext } from "../context/CheckoutContext";
import { findExpirationRenewalDate } from "../utils";
import moment from "moment";
import useBranch from "../hooks/useBranch";

const CancelOffer = ({ purchase, reason }) => {
  const [loading, setLoading] = useState(false);
  const [showPayPal, setShowPayPal] = useState(false);
  const { setPayPalSubscription } = useContext(CheckoutContext);
  const { modalComponent, clearModal } = useContext(ModalContext);
  const { spinner, clearPurchase, redeemCancelOffer } =
    useContext(PurchasesContext);

  const { branch, selectBranch } = useBranch();

  const handleCancel = () => {
    modalComponent(
      "Confirmar",
      <ConfirmCancel reason={reason} handleCancel={clearModal} />
    );
  };

  useEffect(() => {
    return clearPurchase;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showPayPal) {
      selectBranch(1);
      if(branch.branch_id === null) {
        const start_time = moment(
          findExpirationRenewalDate(purchase),
          "DD MMM YYYY"
        )
          .utc()
          .format("YYYY-MM-DDTHH:mm:ssZ");
        setPayPalSubscription({ class_package_id: 19285, start_time, branch });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPayPal, branch]);

  const handlePayPalSubscription = () => {
    setLoading(true);
    setShowPayPal(true);
  };

  const handleRedeemOffer = () => {
    let callback = () => {};
    if (purchase.payment_method_id === 3) {
      callback = handlePayPalSubscription;
    }
    redeemCancelOffer(purchase.purchase_id, reason, callback);
  };

  const renderPurchase = () => {
    if (purchase && purchase !== null) {
      return (
        <div>
          <p>
            Disfruta de un 50% de descuento en tus siguientes 2 cargos de tu
            suscripción a {purchase.class_package.name}
          </p>
          <p className="small text-muted">
            Después de tus siguientes 2 cargos, tu suscripción regresará a su
            precio original de ${purchase.total_payment} MXN
          </p>
          {showPayPal && (
            <div>
              <p className="text-success">
                Haz el click en el botón para completar tu cambio en PayPal.
              </p>
              <div id="paypal-button">
                <div className="spinner-border" />
              </div>
            </div>
          )}
          {!showPayPal && (
            <button
              disabled={spinner || loading}
              onClick={handleRedeemOffer}
              className="btn btn-lg mb-3 d-block btn-primary"
            >
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                "¡Quiero esta Oferta!"
              )}
            </button>
          )}
          <button
            disabled={spinner}
            onClick={handleCancel}
            className="btn btn-sm d-block px-0 py-0 my-4 text-muted text-center"
          >
            No, gracias. Quiero cancelar.
          </button>
        </div>
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <h1 className="h3">¿Nos darías una segunda oportunidad?</h1>
      {renderPurchase()}
    </div>
  );
};

export default CancelOffer;
