import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import Navbar from "../global/Navbar";
import AuthForm from "../auth/AuthForm";
import PlannerTips from "./PlannerTips";
import PlannerFinished from "./PlannerFinished";
import PlannerCalendar from "./PlannerCalendar";
import ApplyWorkoutPlanner from "./ApplyWorkoutPlanner";
import { AuthContext } from "../../context/AuthContext";
import PlannerResultHeader from "./PlannerResultHeader";
import { ModalContext } from "../../context/ModalContext";
import PlannerResultSubheader from "./PlannerResultSubheader";
import { FiltersContext } from "../../context/FiltersContext";
import { WorkoutPlansContext } from "../../context/WorkoutPlansContext";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";


const PlannerResult = ({ workout_plan_id, customer_workout_plan_id }) => {
  const [needsAuth, setNeedsAuth] = useState(false);

  const { workout_plan, getSingleWorkoutPlan } =
    useContext(WorkoutPlansContext);
  const { customer_workout_plan, getSingleCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user, getUsuario } = useContext(AuthContext);
  const { clearFilters } = useContext(FiltersContext);

  useEffect(() => {
    if (user !== null) {
      if (needsAuth) {
        setTimeout(handleApply, 500);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!isNaN(parseInt(workout_plan_id))) {
      getSingleWorkoutPlan(workout_plan_id);
      getUsuario();
    }
  }, [workout_plan_id]);

  useEffect(() => {
    if (!isNaN(parseInt(customer_workout_plan_id))) {
      getSingleCustomerWorkoutPlan(customer_workout_plan_id);
      getUsuario();
    }
  }, [customer_workout_plan_id]);

  useEffect(() => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      if (customer_workout_plan.end_date !== null) {
        handleFinishWorkoutPlanner(customer_workout_plan.end_date);
      }
    }
  }, [customer_workout_plan]);

  const handleFinishWorkoutPlanner = (endDate) => {
    if (moment().isAfter(endDate)) {
      modalComponent(
        "Workout Planner Terminado",
        <PlannerFinished handleApply={handleApply} />
      );
    }
  };

  const handleApply = (callback) => {
    clearModal();
    setTimeout(() => {
      if (user === null) {
        setNeedsAuth(true);
        return modalComponent("Acceder", <AuthForm />, { no_padding: true });
      }
      modalComponent(
        "Aplicar Workout Planner",
        <ApplyWorkoutPlanner
          handleCallback={callback}
          handleCancel={clearModal}
          workout_plan_id={workout_plan.workout_plan_id}
        />
      );
    }, 1000);
  };

  return (
    <div className="container-fluid py-3 py-md-0">
      {!customer_workout_plan_id && <Navbar />}
      <div
        className={`container-fluid px-md-5 px-4 ${
          !customer_workout_plan_id ? "pt-4 mt-5" : ""
        }`}
      >
        <PlannerResultHeader customer_workout_plan={customer_workout_plan} />
        <PlannerResultSubheader
          handleApply={handleApply}
          workout_plan={workout_plan}
          customer_workout_plan_id={customer_workout_plan_id}
        />
        <PlannerCalendar
          handleApply={handleApply}
          workout_plan={workout_plan}
          hideButtons={!customer_workout_plan_id}
          customer_workout_plan_id={customer_workout_plan_id}
        />
      </div>
      <PlannerTips workout_plan={workout_plan} />
    </div>
  );
};

export default PlannerResult;
