import React from "react";
import { S3_ENDPOINT, getValue } from "../../utils";
import moment from "moment";

const SingleCoach = ({ coach, size }) => {
  const renderBirthdate = () => {
    const birthdate = getValue(coach, "birthdate");
    if (moment(birthdate).isValid()) {
      return <p className="mb-0 mt-2">{moment(birthdate).utc().format("DD MMM YYYY")}</p>;
    }
  };

  return (
    <div className="container-fluid mb-3 px-0">
      <div className={`row px-0 p-${size === "sm" ? "2" : "3"} align-items-center`}>
        <div className="col-3">
          {coach.file !== null && coach.file ? (
            <img
              src={`${S3_ENDPOINT}/${coach.file.name}.${coach.file.type}`}
              className={`thumbnail${
                size === "sm" ? "-sm" : ""
              } round coach-thumbnail mw-100 w-100`}
            />
          ) : (
            <div
              className={`thumbnail${
                size === "sm" ? "-sm" : ""
              } round bg-accent user-icon${size === "sm" ? "-sm" : ""}`}
            >
              <i className={`fa fa-user ${size === "sm" ? "" : "fa-2x"}`}></i>
            </div>
          )}
        </div>
        <div className="col-9">
          <h5 className="mb-0">
            {coach.name} {coach.last_name}
          </h5>
          {renderBirthdate()}
        </div>
      </div>
    </div>
  );
};

export default SingleCoach;
