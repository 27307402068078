import React, { useContext, useEffect, useState } from "react";
import { CheckoutContext } from "../context/CheckoutContext";
import { PaymentSourcesContext } from "../context/PaymentSourcesContext";
import {
  S3_ENDPOINT,
  formatMonto,
  validateEmail,
  validatePhoneNumber,
  renderFirstInvoicePeriod,
  renderSubscriptionPeriod,
} from "../utils";
import mastercard from "../assets/images/payment-method-mc.png";
import StripeCheckout from "../components/common/StripeCheckout";
import logo_recortado from "../assets/images/logo_blanco.png";
import visa from "../assets/images/payment-method-visa.png";
import amex from "../assets/images/payment-method-amex.png";
import { ModalContext } from "../context/ModalContext";
import { CartContext } from "../context/CartContext";
import { AuthContext } from "../context/AuthContext";
import { Link } from "@reach/router";
import PaqueteFeatures from "../components/paquetes/PaqueteFeatures";
import { features } from "../utils/paquetes";
import PaymentMethodCard from "../components/payment_sources/PaymentMethodCard";
import CheckoutService from "../services/CheckoutService";
import { navigate } from "@reach/router";
import { handleCheckoutError } from "../utils/checkout";
import useBranch from "../hooks/useBranch";

const Checkout = ({ class_package_id }) => {
  //Singup and Login
  const [login, setLogin] = useState(false);
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [password, setPassword] = useState("");
  const [apellidos, setApellidos] = useState("");
  const { spinner, user, signUp, signIn } = useContext(AuthContext);

  const spinnerUsuario = useContext(AuthContext).spinner;

  //Discounts
  const [codigo, setCodigo] = useState("");

  //Payments
  const [processing, setProcessing] = useState(false);
  const { alert } = useContext(ModalContext);
  const { payment_sources: metodos, getPaymentSources } = useContext(
    PaymentSourcesContext
  );
  const { cart, cart_items, setCart, getCart } = useContext(CartContext);
  
  const { branch, selectBranch } = useBranch();

  const [paymentMethod, setPaymentMethod] = useState("credit");

  //Checkout
  const {
    paquete,
    descuento,
    setPayPal,
    getPaquete,
    spinnerDescuento,
    validarDescuento,
    setPayPalSubscription,
  } = useContext(CheckoutContext);

  useEffect(() => {
    const email = window.localStorage.getItem("email");
    if (email && email !== "" && email !== null) {
      getCart(email);
      setCorreo(email);
    }
  }, []);

  useEffect(() => {
    getPaquete(class_package_id);
    if (user !== null) {
      getPaymentSources();
      getCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (Array.isArray(cart_items)) {
      let items = [...cart_items].filter(
        (item) => String(item.class_package_id) === String(class_package_id)
      );
      if (items.length > 0) setCart(items[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart_items]);

  useEffect(() => {
    if (paquete !== null && user !== null) {
      if (paquete.subscription_period !== null) {
        setPayPalSubscription({ class_package_id, discountCode: codigo, branch, cart });
      } else {
        setPayPal(class_package_id, codigo, cart);
      }

      selectBranch(paquete.branch_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, paquete, descuento]);

  const handleSubmitDescuento = (e) => {
    e.preventDefault();
    if (paquete.free_trial_length > 0) {
      return alert(
        "Lo sentimos. No puedes utilizar un descuento con Prueba Gratis."
      );
    }
    validarDescuento(codigo, class_package_id);
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    signIn(correo, password);
  };

  const handleSuccess = (purchase_id) => {
    setProcessing(false);
    navigate(`/gracias/${purchase_id}`);
  };

  const handleError = (message) => {
    setProcessing(false);
    alert(message);
  };

  const handleSubmitPayment = async (event) => {
    event.preventDefault();
    setProcessing(true);

    CheckoutService.postCheckout(
      class_package_id,
      codigo,
      paymentMethod.payment_source_id,
      cart
    )
      .then((res) => {
        const purchase_id = res.data.purchase_id;
        handleSuccess(purchase_id);
      })
      .catch((error) => handleCheckoutError(error, handleError));
  };

  const handleSubmitSignUp = (e) => {
    e.preventDefault();
    if (nombre === "") {
      return alert("Debes ingresar tu nombre.");
    }
    if (apellidos === "") {
      return alert("Debes ingresar tus apellidos.");
    }
    if (!validateEmail(correo)) {
      return alert("El correo electrónico no es válido.");
    }
    if (String(password).length < 6) {
      return alert("La contraseña debe tener al menos 6 caracteres.");
    }
    if (!validatePhoneNumber(telefono)) {
      return alert("Debes ingresar un número de teléfono válido.");
    }
    signUp(nombre, apellidos, correo, password, telefono);
  };

  const renderCreateAccount = () => {
    if (user === null) {
      if (login) {
        return (
          <div className="container-fluid px-0 mt-3 mb-4">
            <h2>Inicia Sesión</h2>
            <form className="card no-scale" onSubmit={handleSubmitLogin}>
              <label>Correo</label>
              <input
                type="email"
                className="form-control mb-3"
                value={correo}
                onChange={(e) => setCorreo(e.target.value)}
              />
              <label>Contraseña</label>
              <input
                type="password"
                className="form-control mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="row">
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={spinner}
                  >
                    {spinner ? (
                      <div className="spinner-border"></div>
                    ) : (
                      "Entrar"
                    )}
                  </button>
                </div>
                <div className="col-6 text-end">
                  <button
                    className="btn btn-link"
                    onClick={() => setLogin(false)}
                  >
                    ¿Aun no tienes cuenta? Regístrate
                  </button>
                </div>
              </div>
            </form>
          </div>
        );
      }
      return (
        <div className="container-fluid ps-0 mt-3 mb-4">
          <h2>Crea tu cuenta</h2>
          <form className="card no-scale" onSubmit={handleSubmitSignUp}>
            <label>Nombre</label>
            <input
              type="text"
              className="form-control mb-3"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            <label>Apellidos</label>
            <input
              type="text"
              className="form-control mb-3"
              value={apellidos}
              onChange={(e) => setApellidos(e.target.value)}
            />
            <label>Correo</label>
            <input
              type="email"
              className="form-control mb-3"
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
            />
            <label>Contraseña</label>
            <input
              type="password"
              className="form-control mb-3"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Teléfono</label>
            <input
              type="tel"
              className="form-control mb-3"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
            <div className="row">
              <div className="col-6">
                <button type="submit" className="btn btn-primary">
                  {spinnerUsuario ? (
                    <div className="spinner-border"></div>
                  ) : (
                    "Crear cuenta"
                  )}
                </button>
              </div>
              <div className="col-6 text-end">
                <button className="btn btn-link" onClick={() => setLogin(true)}>
                  ¿Ya tienes cuenta? Inicia Sesión
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }
  };

  const renderPresenciales = () => {
    if (paquete.class_amount !== null && paquete.class_amount > 0) {
      return (
        <div className="mt-3">
          <p className="mb-1">
            Acceso a {paquete.class_amount} clases presenciales con vigencia de{" "}
            {paquete.expiration_days} días
          </p>
          {paquete.include_online && (
            <p className="mb-1">
              Acceso a TBM Online ilimitado (clases en línea)
            </p>
          )}
        </div>
      );
    }
  };

  const renderImage = () => {
    if (paquete.image && paquete.image !== null) {
      return (
        <img
          src={`${S3_ENDPOINT}/${paquete.image.name}.${paquete.image.type}`}
          className="mw-100 w-100 m-auto mb-3 paquete-thumbnail"
        />
      );
    }
  };

  const renderPaquete = () => {
    if (paquete && paquete !== null) {
      const price =
        paquete.sale_price !== null ? paquete.sale_price : paquete.price;
      return (
        <div className="card shadow-sm no-scale mb-3">
          {renderImage()}
          <h3 className="mb-0">{paquete.title}</h3>
          <p>{renderPresenciales()}</p>
          {paquete.is_special_event && <p>{paquete.description}</p>}
          {paquete.class_amount > 0 && (
            <ul style={{ listStyleType: "none" }} className="ps-0 ms-0">
              <li>
                <i className="fa fa-check me-1" />
                Reserva tus clases presenciales
              </li>
            </ul>
          )}
          {paquete.include_online && <PaqueteFeatures features={features} />}
          <h5 className="mb-0">
            Total: {"$"}
            {formatMonto(price)}
            {" MXN "}
            {paquete.is_subscription &&
              `cada ${
                paquete.subscription_interval === 1
                  ? ""
                  : paquete.subscription_interval
              }${
                paquete.subscription_period === "month"
                  ? paquete.subscription_interval === 1
                    ? "mes"
                    : "meses"
                  : paquete.subscription_period === "day"
                  ? "días"
                  : "años"
              }`}
          </h5>
        </div>
      );
    }
  };

  const renderDescuento = () => {
    if (user !== null) {
      return (
        <form
          className="card shadow no-scale my-3 p-4"
          onSubmit={handleSubmitDescuento}
        >
          <label className="bold mb-2 d-block">Código de Descuento</label>
          <input
            type="text"
            className="form-control mb-3"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />
          <button
            type="submit"
            className="btn btn-outline-dark"
            disabled={spinnerDescuento}
          >
            {spinnerDescuento ? (
              <div className="spinner-border"></div>
            ) : (
              "Aplicar"
            )}
          </button>
        </form>
      );
    }
  };

  const renderDiscountPeriod = (descuento, paquete) => {
    if (paquete.is_subscription) {
      if (
        !descuento.first_invoice_only ||
        descuento.first_invoice_only === null
      ) {
        return renderSubscriptionPeriod(paquete);
      }
      return `${renderFirstInvoicePeriod(paquete)}`;
    }
  };

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let total =
        paquete.sale_price &&
        paquete.sale_price !== null &&
        paquete.sale_price > 0
          ? paquete.sale_price
          : paquete.price;
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = parseFloat(total) * porcentaje;
      } else {
        total -= descuento.amount;
      }
      total = parseFloat(total).toFixed(2);
      return (
        <div className="container-fluid">
          <h5 className="mb-0">
            Total con Descuento: {"$"}
            {total} MXN {renderDiscountPeriod(descuento, paquete)}
          </h5>
          {descuento.first_invoice_only && (
            <p className="mb-0">
              Después ${formatMonto(paquete.price)}{" "}
              {renderSubscriptionPeriod(paquete)}
            </p>
          )}
        </div>
      );
    }
  };

  const renderMetodosPago = () => {
    if (metodos && metodos !== null) {
      return metodos.map((metodo) => (
        <div
          key={metodo.conekta_payment_source_id}
          className="card p-4 no-scale mb-3"
          onClick={() => setPaymentMethod(metodo)}
        >
          <div className="row mx-0">
            <div className="col-1">
              <input type="radio" checked={paymentMethod === metodo} />
            </div>
            <div className="col-11">
              <div className="row align-items-center">
                <div className="col col-md-4">
                  <img
                    src={
                      metodo.card_type === "mastercard"
                        ? mastercard
                        : metodo.card_type === "visa"
                        ? visa
                        : amex
                    }
                    className="card-type"
                    alt="card type"
                  />
                </div>
                <div className="col col-md-4 capitalize">
                  {metodo.card_type}
                </div>
                <div className="col col-md-4">
                  {"**** "}
                  {metodo.last_digits}
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  const renderPago = () => {
    if (user !== null) {
      return (
        <>
          <h2 className="mb-0">Forma de Pago</h2>
          {renderMetodosPago()}
          <PaymentMethodCard
            name="card"
            label="Tarjeta de Crédito/Débito"
            selected={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          >
            {paymentMethod === "card" && (
              <StripeCheckout
                element_id={class_package_id}
                discountCode={codigo}
                cart={cart}
              />
            )}
          </PaymentMethodCard>

          <div
            className="card no-scale my-3"
            onClick={() => setPaymentMethod("paypal")}
          >
            <div className="row border-bottom mb-3">
              <div className="col-1">
                <input type="radio" checked={paymentMethod === "paypal"} />
              </div>
              <div className="col-11">
                <h4 className=" pb-2 mb-0">PayPal</h4>
              </div>
            </div>
            <div
              id="paypal-button"
              className={`${paymentMethod !== "paypal" ? "d-none" : ""}`}
            ></div>
          </div>

          <div className="container-fluid px-0 text-right">
            {!["card", "paypal", "transfer"].includes(paymentMethod) && (
              <button
                disabled={processing}
                onClick={handleSubmitPayment}
                className="btn btn-primary btn-lg"
              >
                {processing ? (
                  <div className="spinner-border"></div>
                ) : (
                  "Pagar Ahora"
                )}
              </button>
            )}
          </div>
        </>
      );
    }
  };

  const renderDisclaimer = () => {
    if (paquete && paquete !== null) {
      if (paquete.free_trial_length !== null && paquete.free_trial_length > 0) {
        return (
          <div className="card no-scale p-3 mb-3 shadow">
            <h4>Recuerda</h4>
            <p>
              Puedes cancelar tu Prueba Gratis antes de los{" "}
              {paquete.free_trial_length} días y NO SE TE REALIZARÁ NINGÚN
              CARGO.
            </p>
            <p>
              {paquete.free_trial_length} días de prueba previo a la
              suscripción.{" "}
            </p>
            <p className="mb-0">
              Después del período de prueba, tu suscripción se cobrará de manera
              automática. Puedes cancelar en cualquier momento antes del término
              de tu periodo de prueba y no se cobrará. Por lo tanto, tu
              suscripción se renovará automáticamente hasta que la canceles.
              Puedes cancelar en cualquier momento. Los cobros por las
              suscripciones no son reembolsables. Al comenzar el período de
              prueba gratis, nos autorizas a cobrar a través de tu tarjeta de
              crédito o débito, aceptas nuestros Términos de servicio, Políticas
              de cookies, & de privacidad.
            </p>
          </div>
        );
      }
      if (
        paquete.subscription_period !== null &&
        paquete.subscription_period !== ""
      ) {
        return (
          <div className="card no-scale p-4 mb-3 shadow">
            <h4>Recuerda</h4>
            <p className="mb-0">
              * Tu suscripción se renovará automáticamente hasta que la
              canceles. Puedes cancelar en cualquier momento. Los cobros por las
              suscripciones no son reembolsables. Al realizar tu compra nos
              autorizas a cobrar a través de tu tarjeta de crédito o débito,
              aceptas nuestros Términos de servicio, Políticas de cookies y de
              privacidad, además declaras tener al menos 16 años de edad.
            </p>
          </div>
        );
      }
    }
  };

  const renderBillingPeriod = () => {
    if (paquete && paquete !== null) {
      if (paquete.subscription_period === "year") {
        if (paquete.expiration_days < 365) {
          return (
            <div className="card mb-3 p-4 shadow">
              <h4>Acerca de tu Facturación</h4>
              <p>
                Se te realizará un cargo a tu tarjeta de crédito o débito cada
                año, mientras que tus beneficios se renuevan cada{" "}
                {paquete.expiration_days} días.
              </p>
            </div>
          );
        }
      }
      if (
        paquete.subscription_period === "month" &&
        paquete.subscription_interval > 1
      ) {
        return (
          <div className="card mb-3 p-4 shadow">
            <h4>Acerca de tu Facturación</h4>
            <p>
              Se te realizará un cargo a tu tarjeta de crédito o débtio cada{" "}
              {paquete.subscription_interval} meses, mientras que tus beneficios
              se renuevan cada {paquete.expiration_days} días.
            </p>
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row pb-3 bg-dark pt-3">
        <div className="container-fluid mb-2 pb-2 border-bottom ">
          <div className="row align-items-center py-2">
            <div className="col-8 col-md-10">
              <h1 className="text-white mb-0">Checkout</h1>
            </div>
            <div className="col-4 col-md-2 text-right">
              <Link to="/">
                <img
                  src={logo_recortado}
                  className="logo-thumbnail"
                  alt="logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row bg-light pt-md-4 pt-2 pb-5 h-80">
        <div className="col-12 col-md-4 my-2">
          <h2 className="mb-0">Estás Comprando</h2>
          {renderPaquete()}
          {renderResultadoDescuento()}
          {renderDescuento()}
          <div className="show-mobile">
            {renderBillingPeriod()}
            {renderDisclaimer()}
          </div>
        </div>
        <div className="col-12 col-md-8 my-2">
          {renderCreateAccount()}
          {renderPago()}
          {/* {renderMetodosPago()} */}
        </div>
        <div className="container-fluid hide-mobile">
          {renderBillingPeriod()}
          {renderDisclaimer()}
        </div>
      </div>
      <div className="row bg-dark text-white py-2">
        <div className="container-fluid">
          <div className="col col-md-6">
            <Link to="/privacidad" className="text-white">
              Aviso de Privacidad
            </Link>
          </div>
          <div className="col col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
