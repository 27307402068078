import React, { createContext, useReducer } from "react";
import FiltersReducer from "../reducers/FiltersReducer";
import { SET_FILTER, CLEAR_FILTERS } from "../types";

const initialState = {
  class_category_id: null,
  instructor_id: null,
  class_type_id: null,
  video_type_id: null,
  program_id: null,
  completed: null,
  favorites: null,
  duration: null,
  tag_id: null,
  query: "",
  page: 1,
};

export const FiltersContext = createContext(initialState);

export const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FiltersReducer, initialState);

  const loadFilters = () => {
    Object.keys(initialState).forEach((key) => {
      const value = window.localStorage.getItem(key);
      if (value && value !== null) {
        setFilter(key, value);
      }
    });
  };

  const setFilter = (key, value) => {
    window.localStorage.setItem(key, value);
    dispatch({ type: SET_FILTER, payload: { key, value } });
  };

  const clearFilters = () => {
    window.localStorage.clear();
    dispatch({ type: CLEAR_FILTERS, payload: { ...initialState } });
  };

  return (
    <FiltersContext.Provider
      value={{ ...state, setFilter, loadFilters, clearFilters }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
