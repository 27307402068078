import React, { useContext } from "react";
import VideoImage from "./VideoImage";
import VideoInfo from "./VideoInfo";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";

const VideoTeaser = ({ video, hideInfo }) => {
  const { clearModal } = useContext(ModalContext);
  return (
    <div>
      <VideoImage video={video} />
      <div className="row align-items-center border-bottom my-3 pb-2">
        <div className="col-8">
          <h3 className="my-0">{video.name}</h3>
        </div>
        <div className="col-4">
          <button
            onClick={() => {
              navigate(`/mytbm/video/${video.video_id}`);
              clearModal();
            }}
            className="btn w-100 btn-primary"
          >
            <i className="fa fa-play me-2"></i> Ver Video
          </button>
        </div>
      </div>
      <p>{video.description}</p>
      {!hideInfo && <VideoInfo video={video} />}
    </div>
  );
};

export default VideoTeaser;
