import React from "react";

const CancelBenefits = () => {
  return (
    <div className="bg-light p-md-5 p-4">
      <h2 className="display-5 text-center text-accent">
        ¡Lamentamos verte partir!
      </h2>
      <p>
        Antes de que completed el proceso, queremos recordarte los beneficios
        que perderás al cancelar tu membresía:
      </p>
      <ul className="large ps-2" style={{ listStyleType: "none" }}>
        <li className="mb-4">
          <i className="fa fa-check-circle text-primary me-2" /> Acceso a una
          biblioteca de más de 200 videos con entrenamientos divertidos,
          dinámicos y acorde a tus objetivos.
        </li>
        <li className="mb-4">
          <i className="fa fa-check-circle text-primary me-2" /> Acceso a una
          comunidad de más de 500 miembros alrededor del mundo con quien puedes
          interactuar diaramente en la comunidad TBM.
        </li>
        <li className="mb-4">
          <i className="fa fa-check-circle text-primary me-2" /> La posibilidad
          de entrenar en donde tu quieras, cuando tú quieras y cuánto tu quieras
          con TBM Online.
        </li>
      </ul>
      <h2 className="bold text-center">Información Importante</h2>
      <p className="text-primary bold">
        Lee detenidamente la información antes de continuar:
      </p>
      <p>
        Si estás en período de prueba gratuita perderás acceso a tus beneficios
        inmediatamente.
      </p>
      <p>
        De lo contrario, perderás acceso a tus beneficios cuando termine tu
        ciclo actual de facturación.
      </p>
      <p>
        Por ejemplo, si tu suscripción renueva mensualmente cada día 30,
        perderás los beneficios el día 30 del siguiente mes.
      </p>
      <p>
        No se hará ningún reembolso por el último cargo efectuado aún y cuando
        solo haya pasado un minuto de haberse realizado.
      </p>
      <p>
        Una vez que canceles tu suscripción, deberás volver a suscribirte si
        quieres volver a disfrutrar de los beneficios.
      </p>
      <p>
        Si en un futuro deseas volver a suscribirte, el precio de tu membresía
        podría aumentar.
      </p>
    </div>
  );
};
export default CancelBenefits;
