import React, { useContext, useEffect } from "react";
import PlannerResult from "../components/planner/PlannerResult";
import StreakMonth from "../components/streaks/StreakMonth";
import PanelNavbar from "../components/global/PanelNavbar";
import MobileMenu from "../components/common/MobileMenu";
import { AuthContext } from "../context/AuthContext";
import MisReservaciones from "./MisReservaciones";
import Footer from "../components/global/Footer";
import VideoClassType from "./VideoClassType";
import VideoPremiere from "./VideoPremiere";
import MiInformacion from "./MiInformacion";
import EditProgreso from "./EditProgreso";
import VideoProgram from "./VideoProgram";
import Presenciales from "./Presenciales";
import SingleVideo from "./SingleVideo";
import { Router, useLocation } from "@reach/router";
import MisMetodos from "./MisMetodos";
import CancelView from "./CancelView";
import Purchases from "./Purchases";
import PanelHome from "./PanelHome";
import Comunidad from "./Comunidad";
import Invoices from "./Invoices";
import Progreso from "./Progreso";
import Videos from "./Videos";
import Shop from "./Shop";
import Leaderboards from "./Leaderboards";
import Notifications from "./Notifications";
import useBranch from "../hooks/useBranch";

const Panel = () => {
  const { user, signOut } = useContext(AuthContext);
  const { getBranches } = useBranch();

  useEffect(() => {
    getBranches();
  }, []);




  return (
    <div className="container-fluid px-0">
      <MobileMenu signOut={signOut} user={user} />
      <PanelNavbar user={user} signOut={signOut} />
      <div className="main-panel">
        <div className="content overflow-hidden">
          <Router >
            <PanelHome path="/" />
            <Videos path="/online" />
            <Shop path="/paquetes/" />
            <Invoices path="/pagos" />
            <Progreso path="/tracks" />
            <StreakMonth path="/goals" />
            <MisMetodos path="/metodos" />
            <Comunidad path="/comunidad" />
            <Purchases path="/membresias" />
            <Presenciales path="/presencial" />
            <Leaderboards path="/leaderboards" />
            <MiInformacion path="/informacion" />
            <SingleVideo path="/video/:video_id" />
            <Notifications path="/notifications" />
            <EditProgreso path="/track/:track_id" />
            <VideoPremiere path="/online/estrenos" />
            <MisReservaciones path="/reservaciones" />
            <CancelView path="/cancel/:purchase_id" />
            <VideoProgram path="/online/program/:program_url" />
            <VideoClassType path="/online/clases/:class_type_id" />
            <PlannerResult path="/workout-planner/:customer_workout_plan_id" />
          </Router>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Panel;
